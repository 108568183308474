import { Component, OnInit, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { Helper } from 'src/app/shared/helper';
import { SocketService } from 'src/app/services/socket.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit {
  darkTheme = localStorage.getItem('vien-themecolor')
  logoClr:boolean=false;
  IMAGE_URL = environment.IMAGE_URL;
  // buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Sarah Cortney';
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  constructor(
    private sidebarService: SidebarService,
    private _authService: AuthService,
    private langService: LangService,
    public helper: Helper,
    private _socket:SocketService,
    private _commonService:CommonService
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    // window.location.reload();
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    if(this.darkTheme.slice(0,4) == 'dark' ){
      this.logoClr=true;
    }
    if (this.helper.user_details == null) {
      this.helper._route.navigate(['/dispatcher/login'])
    }
    if(this.helper.user_details){
      this.socket(this.helper.user_details._id)

      let json: any = {_id: this.helper.user_details._id,token: this.helper.user_details.token};
      this._commonService.get_user_list(json).then((user_list) => {
        this.helper.created_at.next(user_list.dispatchers.created_at);
      });
    }
    // if (await this.authService.getUser()) {
    //   this.displayName = await this.authService.getUser().then((user) => {
    //     return user.displayName;
    //   });
    // }
    // this.subscription = this.sidebarService.getSidebar().subscribe(
    //   (res) => {
    //     this.sidebar = res;
    //   },
    //   (err) => {
    //     console.error(`An error occurred: ${err.message}`);
    //   }
    // );
  }

  // ngOnDestroy(): void {
  //   this.subscription.unsubscribe();
  // }

  // menuButtonClick = (
  //   e: { stopPropagation: () => void },
  //   menuClickCount: number,
  //   containerClassnames: string
  // ) => {
  //   if (e) {
  //     e.stopPropagation();
  //   }

  //   setTimeout(() => {
  //     const event = document.createEvent('HTMLEvents');
  //     event.initEvent('resize', false, false);
  //     window.dispatchEvent(event);
  //   }, 350);

  //   this.sidebarService.setContainerClassnames(
  //     ++menuClickCount,
  //     containerClassnames,
  //     this.sidebar.selectedMenuHasSubItems
  //   );
  // }

  // mobileMenuButtonClick = (
  //   event: { stopPropagation: () => void },
  //   containerClassnames: string
  // ) => {
  //   if (event) {
  //     event.stopPropagation();
  //   }
  //   this.sidebarService.clickOnMobileMenu(containerClassnames);
  // }

  socket(id: any) {
    let listner = id;
    this._socket.listener(listner).subscribe((res: any) => {          
      if (res.is_admin_decline) {
        this._authService.logout({type:8,is_admin_decline:true}).then(()=>{})
      }
    })
  }

  onSignOut(): void {
    let json:any={type:8};
    this._authService.logout(json).then(()=>{
    })
  }

  // searchKeyUp(event: KeyboardEvent): void {
  //   if (event.key === 'Enter') {
  //     this.search();
  //   } else if (event.key === 'Escape') {
  //     const input = document.querySelector('.mobile-view');
  //     if (input && input.classList) {
  //       input.classList.remove('mobile-view');
  //     }
  //     this.searchKey = '';
  //   }
  // }

  // searchAreaClick(event): void {
  //   event.stopPropagation();
  // }
  // searchClick(event): void {
  //   if (window.innerWidth < environment.menuHiddenBreakpoint) {
  //     let elem = event.target;
  //     if (!event.target.classList.contains('search')) {
  //       if (event.target.parentElement.classList.contains('search')) {
  //         elem = event.target.parentElement;
  //       } else if (
  //         event.target.parentElement.parentElement.classList.contains('search')
  //       ) {
  //         elem = event.target.parentElement.parentElement;
  //       }
  //     }

  //     if (elem.classList.contains('mobile-view')) {
  //       this.search();
  //       elem.classList.remove('mobile-view');
  //     } else {
  //       elem.classList.add('mobile-view');
  //     }
  //   } else {
  //     this.search();
  //   }
  //   event.stopPropagation();
  // }

  // search(): void {
  //   if (this.searchKey && this.searchKey.length > 1) {
  //     this.router.navigate([this.adminRoot + '/pages/miscellaneous/search'], {
  //       queryParams: { key: this.searchKey.toLowerCase().trim() },
  //     });
  //     this.searchKey = '';
  //   }
  // }

  // @HostListener('document:click', ['$event'])
  // handleDocumentClick(event): void {
  //   const input = document.querySelector('.mobile-view');
  //   if (input && input.classList) {
  //     input.classList.remove('mobile-view');
  //   }
  //   this.searchKey = '';
  // }

	@Output() sidemenu: EventEmitter<any> = new EventEmitter();
  // isOpen = false;
  toggle(): void {
    // this.isOpen = !this.isOpen;
    this.sidemenu.emit()
  }

}
