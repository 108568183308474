import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { AuthService } from '../services/auth.service';
import { catchError, tap } from 'rxjs/operators';
import { Helper } from '../shared/helper';
import { TranslateService } from '@ngx-translate/core';
import { NotifiyService } from '../services/notifier.service';

@Injectable()
export class ResInterceptInterceptor implements HttpInterceptor {

  constructor(private _auth: AuthService, private _helper: Helper, private _notifierService: NotifiyService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(tap(evt => {
      if (evt instanceof HttpResponse && evt.body) {
        if (evt.body.success) {
          
          if (req.body && req.body.is_show_success_toast !== false) {
            var successMessage = this._helper.trans.instant('success-code.' + evt.body.message);
            var errorMessage = (evt.body.error_code);

            if (!successMessage.includes(evt.body.message)) {
              this._notifierService.showNotification('success', successMessage);
            }
          }
        } else {
          if (evt.body.success != undefined && !evt.body.success && req.body && req.body.is_show_error_toast !== false) {
            var response = evt.body.error_code ?? evt.body.message;
            var errorMessage = this._helper.trans.instant('error-code.' + response);
            // var errorMessage = (evt.body.status_phrase);
            if (!errorMessage.includes(evt.body.error_code) && !evt.body.error_message) {
              this._notifierService.showNotification('error', errorMessage);
            }
            if (evt.body.error_message) {
              this._notifierService.showNotification('error', evt.body.error_message);
            }

          }
        }
      }
    }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this._notifierService.showNotification('error', err.message)
        }
        return of(err);
      }));

  }
}
