import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { Helper } from "../shared/helper";
import { ApiService } from "./api.service";


@Injectable({ providedIn: 'root' })
export class AuthService {

    public logginUser: UserModel;
    public user_details = JSON.parse(localStorage.getItem('userData'));
    private loginSubject = new BehaviorSubject<UserModel>(this.user_details);
    loginObservable: Observable<UserModel> = this.loginSubject.asObservable();


    get isAuthenticated(): boolean {
        return (!this.logginUser || this.logginUser === null) ? false : true;
    }

    get user_detail() {
        return this.logginUser ? this.logginUser : new UserModel();
    }

    constructor(private _api: ApiService, private helper: Helper) { }

    // Login

    login(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.login, parameters }).then((response) => {
                    if (response.success) {                        
                        this.logginUser = response.data.dispatcher;
                        var localStorageData = {
                            _id: this.logginUser._id,
                            token: this.logginUser.token,
                            country: this.logginUser.country,
                            first_name: this.logginUser.first_name,
                            last_name: this.logginUser.last_name,
                            email: this.logginUser.email,
                        }
                        this.helper.user_details = localStorageData;
                        localStorage.setItem('userData', JSON.stringify(localStorageData))
                        this.loginSubject.next(this.logginUser);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(true);
            }
        })
    }

    logout(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.logout, parameters }).then((res) => {

                    if (res.success) {
                        this.logginUser = null;
                        this.loginSubject.next(this.logginUser);
                        localStorage.removeItem('userData');
                        this.helper.isUpadtedlocalStorage();
                        this.helper._route.navigateByUrl('/')
                        resolve(true)
                    } else {
                        rejects(true)
                    }
                })
            } catch (error) {
                resolve(false);
            }
        })
    }

    // Forgot Password

    forgot_password_Email(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.forgot_password, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

    new_password(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.new_password, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

}
