
import { Injectable, NgZone, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DATE_FORMAT , DEFAULT_IMAGE } from "../../app/constants/constants";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})

export class Helper {
    public DATE_FORMAT = DATE_FORMAT;
    public DEFAULT_IMAGE = DEFAULT_IMAGE ;

    public user_details = JSON.parse(localStorage.getItem('userData'));
    to_fixed_number:number = 2;
    token: any;
    public created_at = new BehaviorSubject<any>(null);
    created_date = this.created_at.asObservable();

    constructor(public http: HttpClient,
        public _route: Router,
        public trans: TranslateService,
        public ngZone: NgZone,
        @Inject(DOCUMENT) private _documentRef: any,) { }

    isUpadtedlocalStorage() {
        this.user_details = JSON.parse(localStorage.getItem('userData'));
    }
    phone_number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101) {
            return false;
        }
        return true;
    }

    number_validation(evt, value = 0) {
        if (evt.key === '.' && value != null && (value.toString().indexOf('.') === value.toString().lastIndexOf('.'))) {
            return false;
        }
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            if (charCode == 46) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }


    space_validation(evt) {
        if (evt.code == "Space" && evt.target.value.length < 1) {
            return false;
        }
        return true
    }

    check_email(email) {
        var email_validation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email_validation.test(email)) {
            return false;
        }
        return true;
    }

    get generate_new_uuid(): string {
        return 'xxxxxxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    get uuid(): string {
        return 'xxxxxxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    loadGoogleScript(url) {
        return new Promise((resolve, reject) => {
            if (!document.querySelector('script[src="' + url + '"]')) {
                const script = this._documentRef.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.text = ``;
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            } else {
                resolve(true);
            }
        })
    }
    downloadImage(url: string, fileName: string) {
        const a: any = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };
    downloadUrl(url: string) {
        return this.http.get(url, { responseType: 'blob' }).pipe(switchMap(response => this.readFile(response)));
    }
    private readFile(blob: Blob): Observable<string> {
        return Observable.create(obs => {
            const reader = new FileReader();
            reader.onerror = err => obs.error(err);
            reader.onabort = err => obs.error(err);
            reader.onload = () => obs.next(reader.result);
            reader.onloadend = () => obs.complete();
            return reader.readAsDataURL(blob);
        });
    }
    downloadFile(res: any) {
        this.http.get(res, { responseType: 'blob' as 'blob' }).subscribe(fileData => {
            const blob: any = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let filename = res.split('xlsheet')
            let link = document.createElement("a");
      
            if (link.download !== undefined) {
              let url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename[1]);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
          );
    }
}


