<div [ngClass]="{'app-modal': true, 'shown': isOpen}" (click)="menuClick($event)">
    <div class="">
        <tabset class="card-tabs mb-0" [justified]="true">
            <tab heading="{{ 'mapcomponent.new' | translate}}" class="h-100 pt-3 ml-4">
                <div class="form pr-4">
                    <!-- <form> -->
                    <div class="form-row">
                        <div class="form-group col-8">
                            <!-- <label for="search">{{ 'forms.search' | translate }}</label> -->
                            <input type="text" class="form-control rounded-pill" id="search"
                                placeholder="{{ 'mapcomponent.search' | translate }}" [(ngModel)]="SearchText">
                        </div>
                        <div class="form-group col-4">
                            <button type="button" class="btn btn-primary w-100 d-block text-center"
                                (click)="openModal()">{{ 'mapcomponent.new' | translate }}</button>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>
                <div class="chat-app-tab-pane-contacts">
                    <perfect-scrollbar class="scroll pr-0 mr-0"
                        [config]="{suppressScrollX: true, wheelPropagation: false}">
                        <div class="row">
                            <div class="col-12 pt-3 hoverable"
                                *ngFor="let data of request_list | requestfilter:SearchText;let i = index"
                                (click)="selected_user(data)">
                                <div
                                    class="align-self-center d-flex flex-row justify-content-between min-width-zero mb-2">
                                    <div class="min-width-zero">
                                        <a href="javascript:;"
                                            [ngClass]="{'color-theme-1':(selected_trip_id == request_list[i]._id)}">
                                            <p class="list-item-heading mb-1 truncate text-small">
                                                {{data.user_detail.first_name}}</p>
                                            <p class="mb-1 list-item-heading text-small">{{data.user_detail.phone}}</p>
                                        </a>
                                    </div>
                                    <div class="min-width-zero text-right">
                                        <p class="mb-2 truncate text-right text-muted text-small"
                                            [ngClass]="{'text-primary':(selected_trip_id == request_list[i]._id)}">
                                            #{{data.unique_id}} <br> {{data.created_at | date:helper.DATE_FORMAT.D_MMM_H_MM_A}}
                                        </p>
                                        <!-- <p class="mb-1 truncate text-right text-muted text-small">{{'mapcomponent.pickup-code' | translate}}{{d.code}}</p> -->
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center" *ngIf="data.server_start_time_for_schedule">
                                    <p class="mb-2 truncate text-right text-small font-weight-bold" [ngClass]="{'text-primary':(selected_trip_id == request_list[i]._id)}">
                                        {{'heading-title.scheduled-time'| translate}}
                                    </p>
                                    <p class="mb-2 truncate text-right text-small font-weight-bold" [ngClass]="{'text-primary':(selected_trip_id == request_list[i]._id)}">
                                        {{data.server_start_time_for_schedule | date:helper.DATE_FORMAT.D_MMM_H_MM_A}}
                                    </p>
                                </div>
                                <div [ngClass]="{'text-primary':(selected_trip_id == request_list[i]._id)}"
                                    class="d-flex justify-content-between align-items-center">
                                    <div class="w-60">
                                        <p class="mb-2 truncate text-small white-space-normal c-pointer">
                                            {{data.destination_address}}</p>
                                        <p class="mb-2 truncate text-small font-weight-bold white-space-normal c-pointer">
                                            {{data.typename}}</p>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <button class="btn btn-primary height-fit mb-2" style="padding: 0.3rem 1.25rem;"
                                            *ngIf="(data.is_provider_accepted == 3 || data.current_providers.length == 0) && data.is_schedule_trip && data.time_left_to_responds_trip > 0"
                                            (click)="assign(assigndriver,i)">{{'button-title.assign' |
                                            translate}}</button>
                                        <button class="btn btn-primary height-fit mb-2" style="padding: 0.3rem 1.25rem;"
                                            *ngIf="data.is_schedule_trip == false && ((data.is_provider_accepted == 3) || (data.current_providers.length == 0))"
                                            (click)="assign(assigndriver,i)">{{'button-title.assign' |
                                            translate}}</button>
                                        <button class="btn btn-outline-primary height-fit text-right"
                                            style="padding: 0.3rem 1.25rem;" (click)="cancel_ride_modal(cancel_current_ride,request_list[i])">{{'button-title.cancel' |
                                            translate}}</button>
                                    </div>
                                </div>
                                <div class="separator w-100 my-2"></div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </tab>
            <tab heading="{{ 'mapcomponent.accepted' | translate}}" class="pt-3 h-100 ml-4">
                <div class="form pr-4">
                    <!-- <form> -->
                    <div class="form-row">
                        <div class="form-group col-8">
                            <!-- <label for="search">{{ 'forms.search' | translate }}</label> -->
                            <input type="text" class="form-control rounded-pill" id="search"
                                placeholder="{{ 'mapcomponent.search' | translate }}" [(ngModel)]="SearchText">
                        </div>
                        <div class="form-group col-4">
                            <button type="button" class="btn btn-primary w-100 d-block text-center"
                                (click)="openModal()">{{ 'mapcomponent.new' | translate }}</button>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>
                <div class="chat-app-tab-pane-contacts">
                    <perfect-scrollbar class="scroll pr-0 mr-0"
                        [config]="{suppressScrollX: true, wheelPropagation: false}">
                        <div class="row">
                            <div class="col-12 pt-3 hoverable"
                                *ngFor="let data of accepted_request_list | requestfilter:SearchText;let i = index"
                                (click)="selected_user(data)">
                                <div
                                    class="align-self-center d-flex flex-row justify-content-between min-width-zero mb-2">
                                    <div class="min-width-zero">
                                        <a href="javascript:;"
                                            [ngClass]="{'color-theme-1':(selected_trip_id == accepted_request_list[i]._id)}">
                                            <p class="list-item-heading mb-1 truncate text-small">
                                                {{data.user_detail.first_name}}</p>
                                            <p class="mb-1 list-item-heading text-small">{{data.user_detail.phone}}</p>
                                        </a>
                                    </div>
                                    <div class="min-width-zero text-right">
                                        <p class="mb-1 truncate text-right text-muted text-small"
                                            [ngClass]="{'text-primary':(selected_trip_id == accepted_request_list[i]._id)}">
                                            #{{data.unique_id}} <br> {{data.created_at | date:helper.DATE_FORMAT.D_MMM_H_MM_A}}
                                        </p>
                                        <!-- <p class="mb-1 truncate text-right text-muted text-small">{{'mapcomponent.pickup-code' | translate}}{{d.code}}</p> -->
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center" *ngIf="data.server_start_time_for_schedule">
                                    <p class="mb-2 truncate text-right text-small font-weight-bold" [ngClass]="{'text-primary':(selected_trip_id == accepted_request_list[i]._id)}">
                                        {{'heading-title.scheduled-time'| translate}}
                                    </p>
                                    <p class="mb-2 truncate text-right text-small font-weight-bold" [ngClass]="{'text-primary':(selected_trip_id == accepted_request_list[i]._id)}">
                                        {{data.server_start_time_for_schedule | date:helper.DATE_FORMAT.D_MMM_H_MM_A}}
                                    </p>
                                </div>
                                <div [ngClass]="{'text-primary':(selected_trip_id == accepted_request_list[i]._id)}">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="w-60">
                                            <p class="mb-2 truncate text-small white-space-normal c-pointer">
                                                {{data.destination_address}}</p>
                                            <p class="mb-2 truncate text-small font-weight-bold white-space-normal c-pointer">
                                                {{data.typename}}</p>
                                        </div>
                                        <button class="btn btn-outline-primary height-fit text-right"
                                            style="padding: 0.3rem 1.25rem;" (click)="cancel_ride_modal(cancel_current_ride,accepted_request_list[i])">{{'button-title.cancel' |
                                            translate}}</button>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom pb-3">
                                        <span class="badge badge-pill badge-outline-primary">{{'label-title.accepted' |
                                            translate}}</span>
                                        <p class="mb-1 truncate text-small">{{data.provider_detail.first_name}}
                                            {{data.provider_detail.last_name}}</p>
                                    </div>
                                </div>
                                <div class="separator w-100 my-2"></div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </tab>
            <tab heading="{{ 'mapcomponent.arrived' | translate}}" class="pt-3 ml-4 h-100">
                <div class="form pr-4">
                    <!-- <form> -->
                    <div class="form-row">
                        <div class="form-group col-8">
                            <!-- <label for="search">{{ 'forms.search' | translate }}</label> -->
                            <input type="text" class="form-control rounded-pill" id="search"
                                placeholder="{{ 'mapcomponent.search' | translate }}" [(ngModel)]="SearchText">
                        </div>
                        <div class="form-group col-4">
                            <button type="button" class="btn btn-primary w-100 d-block text-center"
                                (click)="openModal()">{{ 'mapcomponent.new' | translate }}</button>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>
                <div class="chat-app-tab-pane-contacts">
                    <perfect-scrollbar class="scroll pr-0 mr-0"
                        [config]="{suppressScrollX: true, wheelPropagation: false}">
                        <div class="row">
                            <div class="col-12 pt-3 hoverable"
                                *ngFor="let data of arrived_request_list | requestfilter:SearchText;let i = index"
                                (click)="selected_user(data)">
                                <div
                                    class="align-self-center d-flex flex-row justify-content-between min-width-zero mb-2">
                                    <div class="min-width-zero">
                                        <a href="javascript:;"
                                            [ngClass]="{'color-theme-1':(selected_trip_id == arrived_request_list[i]._id)}">
                                            <p class="list-item-heading mb-1 truncate text-small">
                                                {{data.user_detail.first_name}}</p>
                                            <p class="mb-1 list-item-heading text-small">{{data.user_detail.phone}}</p>
                                        </a>
                                    </div>
                                    <div class="min-width-zero text-right">
                                        <p class="mb-1 truncate text-right text-muted text-small"
                                            [ngClass]="{'text-primary':(selected_trip_id == arrived_request_list[i]._id)}">
                                            #{{data.unique_id}} <br> {{data.created_at | date:helper.DATE_FORMAT.D_MMM_H_MM_A}}
                                        </p>
                                        <!-- <p class="mb-1 truncate text-right text-muted text-small">{{'mapcomponent.pickup-code' | translate}}{{d.code}}</p> -->
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center" *ngIf="data.server_start_time_for_schedule">
                                    <p class="mb-2 truncate text-right text-small font-weight-bold" [ngClass]="{'text-primary':(selected_trip_id == arrived_request_list[i]._id)}">
                                        {{'heading-title.scheduled-time'| translate}}
                                    </p>
                                    <p class="mb-2 truncate text-right text-small font-weight-bold" [ngClass]="{'text-primary':(selected_trip_id == arrived_request_list[i]._id)}">
                                        {{data.server_start_time_for_schedule | date:helper.DATE_FORMAT.D_MMM_H_MM_A}}
                                    </p>
                                </div>
                                <div [ngClass]="{'text-primary':(selected_trip_id == arrived_request_list[i]._id)}">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="w-60">
                                            <p class="mb-2 truncate text-small white-space-normal c-pointer">
                                                {{data.destination_address}}</p>
                                            <p class="mb-2 truncate text-small font-weight-bold white-space-normal c-pointer">
                                                {{data.typename}}</p>
                                        </div>
                                        <button class="btn btn-outline-primary height-fit text-right"
                                            style="padding: 0.3rem 1.25rem;" (click)="cancel_ride_modal(cancel_current_ride,arrived_request_list[i])">{{'button-title.cancel' |
                                            translate}}</button>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom pb-3">
                                        <span class="badge badge-pill badge-outline-primary">{{'label-title.arrived' |
                                            translate}}</span>
                                        <p class="mb-1 truncate text-small">{{data.provider_detail.first_name}}
                                            {{data.provider_detail.last_name}}</p>
                                    </div>
                                </div>
                                <div class="separator w-100 my-2"></div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </tab>
            <tab heading="{{ 'mapcomponent.on-ride' | translate}}" class="pt-3 ml-4 h-100">
                <div class="form pr-4">
                    <!-- <form> -->
                    <div class="form-row">
                        <div class="form-group col-8">
                            <!-- <label for="search">{{ 'forms.search' | translate }}</label> -->
                            <input type="text" class="form-control rounded-pill" id="search"
                                placeholder="{{ 'mapcomponent.search' | translate }}" [(ngModel)]="SearchText">
                        </div>
                        <div class="form-group col-4">
                            <button type="button" class="btn btn-primary w-100 d-block text-center" (click)="openModal()">{{ 'mapcomponent.new' |
                                translate }}</button>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>
                <div class="chat-app-tab-pane-contacts">
                    <perfect-scrollbar class="scroll pr-0 mr-0"
                        [config]="{suppressScrollX: true, wheelPropagation: false}">
                        <div class="row">
                            <div class="col-12 pt-3 hoverable"
                                *ngFor="let data of started_request_list | requestfilter:SearchText;let i = index"
                                (click)="selected_user(data)">
                                <div
                                    class="align-self-center d-flex flex-row justify-content-between min-width-zero mb-2">
                                    <div class="min-width-zero">
                                        <a href="javascript:;"
                                            [ngClass]="{'color-theme-1':(selected_trip_id == started_request_list[i]._id)}">
                                            <p class="list-item-heading mb-1 truncate text-small">
                                                {{data.user_detail.first_name}}</p>
                                            <p class="mb-1 list-item-heading text-small">{{data.user_detail.phone}}</p>
                                        </a>
                                    </div>
                                    <div class="min-width-zero text-right">
                                        <p class="mb-1 truncate text-right text-muted text-small"
                                            [ngClass]="{'text-primary':(selected_trip_id == started_request_list[i]._id)}">
                                            #{{data.unique_id}} <br> {{data.created_at | date:helper.DATE_FORMAT.D_MMM_H_MM_A}}
                                        </p>
                                        <p class="mb-1 truncate text-right text-muted text-small"
                                            [ngClass]="{'text-primary':(selected_trip_id == started_request_list[i]._id)}">
                                            <!-- {{'mapcomponent.pickup-code' | translate}}&nbsp;123456</p> -->
                                        <!-- {{d.code}} -->
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center" *ngIf="data.server_start_time_for_schedule">
                                    <p class="mb-2 truncate text-right text-small font-weight-bold" [ngClass]="{'text-primary':(selected_trip_id == started_request_list[i]._id)}">
                                        {{'heading-title.scheduled-time'| translate}}
                                    </p>
                                    <p class="mb-2 truncate text-right text-small font-weight-bold" [ngClass]="{'text-primary':(selected_trip_id == started_request_list[i]._id)}">
                                        {{data.server_start_time_for_schedule | date:helper.DATE_FORMAT.D_MMM_H_MM_A}}
                                    </p>
                                </div>
                                <div [ngClass]="{'text-primary':(selected_trip_id == started_request_list[i]._id)}">
                                    <div class="w-60">
                                        <p class="mb-2 truncate text-small white-space-normal c-pointer">
                                            {{data.destination_address}}</p>
                                        <p class="mb-2 truncate text-small font-weight-bold white-space-normal c-pointer">
                                            {{data.typename}}</p>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom pb-3">
                                        <span class="badge badge-pill badge-outline-primary">{{'label-title.started' |
                                            translate}}</span>
                                        <p class="mb-1 truncate text-small">{{data.provider_detail.first_name}}
                                            {{data.provider_detail.last_name}}</p>
                                    </div>
                                </div>
                                <div class="separator w-100 my-2"></div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </tab>
        </tabset>
    </div>
    <ng-content></ng-content>
    <!-- <a class="app-modal-button d-inline-block d-none" href="javascript:;" (click)="toggle()">
        <i class="simple-icon-options"></i>
    </a> -->
</div>
<!-- <ng-template #template [ngClass]="{'app-menu': true}">
    <modal-container role="dialog" tabindex="-1" class="modal show" style="display: block;" aria-modal="true">
        <div role="document" class="modal-dialog modal-right">
            <div class="modal-content">
                <div class="">
                    <tabset class="card-tabs mb-0" [justified]="true">
                        <tab heading="{{ 'mapcomponent.free' | translate}}" class="h-100 pt-3 ml-4">
                          gfdgfdg
                        </tab>
                        <tab heading="{{ 'mapcomponent.on-job' | translate}}" class="pt-3 h-100 ml-4">
                          fgdgdgdfgdfgfgdg                            
                        </tab>
                        <tab heading="{{ 'mapcomponent.offline' | translate}}" class="pt-3 ml-4 h-100">
                          sadcxv
                        </tab>
                      </tabset>
                </div>
            </div>
        </div>
    </modal-container>
</ng-template> -->

<ng-template #assigndriver>

    <div class="p-4 text-center">
        <h3 class="mb-4">{{'label-title.assign_ride' | translate}} ( {{selected_trip_unique_id}} )
            <button type="button" class="close pull-right" aria-label="Close" (click)="SchedulemodalClose.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </h3>
        <div class="">
            <button id="button-basic" type="button" class="btn btn-lg btn-primary mb-2"
                (click)="send_request();SchedulemodalClose.hide()">
                {{'button-title.auto_assign' | translate}}
            </button>
            <button id="button-basic" type="button" class="btn btn-lg btn-secondary"
                (click)="manual_request();SchedulemodalClose.hide()">
                {{'button-title.manual_assign' | translate}}
            </button>
        </div>
    </div>

</ng-template>

<ng-template #cancel_current_ride>

    <div class="p-4 text-center">
        <button type="button" class="close pull-right" aria-label="Close" (click)="canceltripmodal.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="mb-4">{{'label-title.cancel-trip' | translate}} ( {{selected_trip_unique_id}} )</h3>
        <div class="">
            <p class="mb-2">{{'label-title.are_you_sure_want_to_cancel_ride' | translate}}</p>
            <button id="button-basic" type="button" class="btn btn-lg btn-primary mb-0"
                (click)="cancel_ride()">
                {{'label-title.cancel-trip' | translate}}
            </button>
            <!-- <button id="button-basic" type="button" class="mx-2 mx-sm-0 mb-2 mb-sm-0 btn btn-lg btn-outline-primary"
                (click)="canceltripmodal.hide()">
                {{'button-title.close' | translate}}
            </button> -->
        </div>
    </div>

</ng-template>