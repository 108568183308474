export interface IContacts {
    id: number;
    name: string;
    phone: string;
    location: string;
    id2: string;
    time: string;
    code: number;
    status: string;
    provider: string;
}

const data: IContacts[] = [
    {
        id: 1,
        name: "Max",
        phone: "+1234567890",
        location: "New York, NY, USA",
        id2: "#541",
        time: "09:57 AM",
        code: 123456,
        status: "Deliveryman is coming",
        provider: "Test Provider",
    },
    {
        id: 2,
        name: "Test",
        phone: "+1234567890",
        location: "New York, NY, USA",
        id2: "#541",
        time: "09:57 AM",
        code: 123456,
        status: "Deliveryman started his delivery",
        provider: "Test Provider",
    },
];

export default data;
