export var apiColletions = {
    // excel sheet
    "dispatcher_completed_requests_export_excel":"/history_excel",

    //Common
    "get_countries": "/country_list",
    "get_user_setting_detail": "/get_user_setting_detail",
    "get_language_list":"/get_language_list",

    //users
    "dispatcher_create_trip_json":"/create_trip_details",
    "checkuser_json":"/check_user",
    "search_dispatcher_user":"/search_dispatcher_user",
    
    //bank 
    "add_bank_details":"/add_bank_detail_json",
    "get_bank_details":"/dispatcher_bank_detail_json",
    "update_bank_details":"/update_bank_details",

    // vehicle
    "typelist_selectedcountrycity": "/select_city_service",
    "getfareestimate": "/getfareestimate",
    "get_nearby_provider": "/get_near_by_provider",
    "getnearbyprovider":"/get_near_by_provider",
    "get_server_time" : "/get_server_time",
    "typelist_for_dispatcher":"/service_list",
    
    // auth
    "login":"/login",
    "logout":"/sign_out",
    "forgot_password":"/forgot_password",
    "new_password":"/update_password",

    //completed requests
    "completed_requests":"/history",

    //future requests
    "getfuturetrip":"/future",

    //trip cancle
    "canceltrip":"/canceltrip",

    //provider
    "get_all_provider":"/get_all_provider",

    //create trip
    "createtrip":"/createtrip",

    //request
    "dispatcher_new_request":"/get_new_request",
    "send_request":"/send_request",

    //gettrippath
    "getgooglemappath":"/getgooglemappath",
}