import { Component, OnInit, OnDestroy, HostListener, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import data, { IContacts } from 'src/app/data/contacts';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { HistoryService } from 'src/app/services/history.service';
import { Helper } from '../../../shared/helper';
import { SocketService } from 'src/app/services/socket.service';
@Component({
  selector: 'app-sidebar2',
  templateUrl: './sidebar2.component.html',
  styles: []
})
export class Sidebar2Component implements OnInit,OnDestroy {

  @Output() trip_data: EventEmitter<any> = new EventEmitter();
  @Output() manual_data: EventEmitter<any> = new EventEmitter();

  SchedulemodalClose: BsModalRef;
  canceltripmodal: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-sm modal-dialog-centered'
  };

  data: IContacts[] = data;
  isOpen = true;
  is_click: boolean = false;
  request_list: any;
  accepted_request_list: any;
  arrived_request_list: any;
  started_request_list: any;
  selected_trip_id: string;
  selected_trip_unique_id: number;
  SearchText: any;
  user_detail: any;
  trip_id: string;

  constructor(private _socket: SocketService,private modalService: BsModalService, public helper: Helper, private _dispatcherService: DispatcherService, private _historyService: HistoryService) { }
  ngOnDestroy(): void {
    this._dispatcherService = null;
  }

  ngOnInit(): void {
    if (this.is_click == false) {
      this.helper._route.navigate(['/app/map'])
    }
    if(this.helper.user_details){
      this.socket(this.helper.user_details._id)
    }
    this.dispatcher_new_request(this.helper.user_details._id)
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  menuClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  openModal(): void {
    this.is_click = true;
    if (this.is_click == true) {
      this.helper._route.navigate(['/app/create-trip'])
    } else {
      this.helper._route.navigate(['/app/map'])
    }
    this.is_click = false;
  }

  socket(id:any) {
    let listner = id
    this._socket.listener(listner).subscribe((res: any) => {
      this.dispatcher_new_request(res.service_id)
    })
  }

  dispatcher_new_request(id) {
    let json: any = { dispatcher_id: id}
    if(this._dispatcherService){
      this._dispatcherService.dispatcher_new_request(json).then((response) => {
        this.request_list = response.request_list;
        this.accepted_request_list = response.accepted_request_list;
        this.arrived_request_list = response.arrived_request_list;
        this.started_request_list = response.started_request_list;
        if(typeof(this.selected_trip_id) != 'undefined'){
          let req = this.request_list.findIndex(id => id != this.selected_trip_id)
          let accept = this.accepted_request_list.findIndex(id => id != this.selected_trip_id)
          let arrive = this.arrived_request_list.findIndex(id => id != this.selected_trip_id)
          let start = this.started_request_list.findIndex(id => id != this.selected_trip_id)
          if(req && accept && arrive && start){
            this.trip_data.emit(null)
          }
        }
      })
    }
  }

  selected_user(data) {
    this.selected_trip_id = data._id;
    this.user_detail = data.user_detail;
    this.trip_data.emit(data);
  }

  assign(modal: TemplateRef<any>, i): void {
    this.SchedulemodalClose = this.modalService.show(modal, this.config);
    this.trip_id = this.request_list[i]._id;
    this.selected_trip_unique_id = this.request_list[i].unique_id;
  }

  cancel_ride_modal(modal: TemplateRef<any>, trip_data): void {
    this.canceltripmodal = this.modalService.show(modal, this.config);
    this.selected_trip_id = trip_data._id;
    this.selected_trip_unique_id = trip_data.unique_id;
  }

  send_request() {
    let json: any = { trip_id: this.trip_id, provider_type: 1, provider_full_name: '', provider_phone: '' }
    this._dispatcherService.send_request_from_dispatcher(json).then((response) => {})
  }

  manual_request() {
    this.manual_data.emit(2);
  }

  cancel_ride() {
    setTimeout(() => {
      let json: any = { user_id: this.user_detail._id, token: this.user_detail.token, trip_id: this.selected_trip_id }
      this._historyService.cancle_Trip(json).then((response) => {
        if(response.success){
          this.canceltripmodal.hide();
          this.dispatcher_new_request(this.helper.user_details._id);
          this.trip_data.emit(null);
        }
      })
    }, 1000);
  }
}
