import { Injectable } from '@angular/core';
import { apiColletions } from "../constants/api_collection";
// import { CountryModel } from "../models/country.model";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private _api: ApiService) { }

  get_setting_detail(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        parameters.is_show_error_toast = false;
        parameters.is_show_success_toast = false;
        this._api.post({ url: apiColletions.get_user_setting_detail, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  get_user_list(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        parameters.is_show_error_toast = false;
        parameters.is_show_success_toast = false;
        this._api.post({ url: apiColletions.dispatcher_create_trip_json, parameters }).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  completed_requests_export_excel(parameters  ){
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.dispatcher_completed_requests_export_excel, parameters}).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  
  getLanguageList(): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.get({ url: apiColletions.get_language_list ,parameters:[]}).then((res) => {

          if (res.success) {
            resolve(res.data)
          } else {
            rejects(null)
          }
        })
      } catch (error) {
        resolve(null);
      }
    })
  }
}
